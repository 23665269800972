import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import style from '../styles/post.module.css'
import { getPostAside } from '../helpers/index'

const PostLink = ({ meta, excerpt, readingTime }) => (
  <div className={style.post}>
    <div className={style.postContent}>
      <div className={style.postLink}>
        {meta.type === 'link' ? (
          <a href={meta.link}>
            <h2 className={style.title}>{meta.title}</h2>
          </a>
        ) : (
          <h2 className={style.title}>
            <Link to={meta.path}>{meta.title}</Link>
          </h2>
        )}
        <p className={style.aside}>{getPostAside(meta.type, readingTime)}</p>
      </div>
      <p>{excerpt}</p>
    </div>
  </div>
)

PostLink.propTypes = {
  meta: PropTypes.object,
  excerpt: PropTypes.string,
  readingTime: PropTypes.string,
}

export default PostLink
