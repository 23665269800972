module.exports.toKebabCase = function(value) {
  return value.replace(new RegExp('(\\s|_|-)+', 'gmi'), '-')
}

module.exports.getPostAside = function(type, readingTime) {
  switch (type) {
    case 'link':
      return '→'
    case 'quote':
      return '@'
    default:
      return readingTime
  }
}
